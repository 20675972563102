import React from 'react'
import Layout from '../components/Layout';
import { Container } from "react-bootstrap";
import '../styles/courses.css'
import { useEffect } from 'react'
import AOS from 'aos';
import Head from '../components/Head';


function Courses() {

    useEffect(() => {
        AOS.init({
          duration : 1500
        });
        AOS.refresh();
    
      }, []);

    return (
        <div className='overflow-hidden'>
            <Head title="Courses"/>
            <Layout>
                <Container fluid className="">
                    <section className="prospect"  >

                        <div className="row">
                            <div className="col-lg-6 mb-5 mb-lg-0">
                                <div className="prospectImage" data-aos="fade-right"></div>
                            </div>
                            <div className="col-lg-6 d-flex justify-content-center align-items-center">
                                <div className="">
                                    <header className="prospectTitle ms-5 ms-lg-0" as="h1" data-aos="flip-up">Double Your Prospects</header>

                                    <p className="prospectContent ms-5 ms-lg-0">
                                        Bridging the gap between Goals and Acheviments through creative effort With <span style={{ color: '#f04021', fontWeight: '600' }}>KSSS college</span>
                                    </p>

                                    <div className='row prospectCard'>
                                        <div className=" col-3  me-5 me-lg-0" data-aos="zoom-in" >
                                            <div className='d-flex justify-content-center'>
                                                <div className='prospectIcon prospectIcon1 '></div>
                                            </div>
                                            <div className='prospectCardTitle'>Professional Courses</div>
                                        </div>
                                        <div className="col-3  me-5 me-lg-0" data-aos="zoom-in" data-aos-duration='2000'>
                                            <div className='d-flex justify-content-center'>
                                                <div className='prospectIcon prospectIcon2'></div>
                                            </div>
                                            <div className='prospectCardTitle'>Live Learning</div>
                                        </div>
                                        <div className="col-3 " data-aos="zoom-in" data-aos-duration='1800'>
                                            <div className='d-flex justify-content-center'>
                                                <div className='prospectIcon prospectIcon3'></div>
                                            </div>
                                            <div className='prospectCardTitle'>Expert Teachers</div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>

                    <section className="courseVisionContainer"  >
                        <div className="courseVision">
                            <p data-aos="flip-up"><span style={{fontSize:'3.2rem'}} >W</span>e give utmost attention to train students with detailed syllabus, real – time examples and by using effective pedagogical tools.</p>
                            <br />
                            <p data-aos="flip-up" data-aos-duration='2000'><span style={{fontSize:'3.2rem'}} >W</span>e strive to create opportunity to support the students to prepare mentally and physically to face the challenges of competitive examination.</p>
                        </div>

                    </section>


                    <section className="coursesBackground d-flex align-items-center justify-content-center text-light" >
                        <h1 data-aos="zoom-in">
                            Explore our
                            <br />
                            Popular Courses
                        </h1>
                    </section>

                    <section className=''>
                        <div className='scienceSection row'>
                            
                            <div className='scienceLeftPart col-lg-5 col-12  d-flex align-items-center'>
                                <span class="imageDots imageDotsScience" data-aos="fade-right"></span>
                                <div className="courseImage scienceImage" data-aos="fade-right"></div>
                                <h1 className="courseRound courseRoundScience">Science</h1>

                            </div>


                            <div className='col-lg-7 col-12  position-relative '>
                                <div className=' courseCardPart1  scienceCardPart1' data-aos="flip-left">
                                    <h1 style={{ textAlign: 'center' }}>Part 1</h1>
                                    <hr />
                                    <ul className="courseUl" style={{ paddingLeft: "0"}}>
                                        <li className='star'>Kannada</li>
                                        <li className='star'>English</li>
                                        <li className='star'>Hindi</li>
                                        <li className='star'>Sanskrit</li>
                                    </ul>

                                </div>

                                <div className='courseBg scienceBg' data-aos="zoom-in" data-aos-duration='2000'></div>

                                <div className='courseCardPart2  scienceCardPart2' data-aos="flip-right">
                                    <h1 style={{ textAlign: 'center' }}>Part 2</h1>
                                    <hr />
                                    <div className='row'>
                                        <div className='col-lg-9 col-8'>
                                            <ul>
                                                <li className='star'>Physics</li>
                                                <li className='star'>Chemistry</li>
                                                <li className='star'>Mathamatics</li>
                                                <li className='star'>Biology</li>
                                            </ul>
                                        </div>
                                        <div className='col-lg-3 col-4 d-flex justify-content-center align-items-center' style={{ borderLeft: '2px solid white' }}>
                                            PCMB
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row'>
                                        <div className='col-lg-9 col-8'>
                                            <ul>
                                                <li className='star'>Physics</li>
                                                <li className='star'>Chemistry</li>
                                                <li className='star'>Mathamatics</li>
                                                <li className='star'>Computer Science</li>
                                            </ul>
                                        </div>
                                        <div className='col-lg-3 col-4 d-flex justify-content-center align-items-center ' style={{ borderLeft: '2px solid white' }}>
                                            PCMC
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>



                        <div className='commerceSection row '>

                            <div className='col-lg-7 col-12  position-relative '>
                                <div className=' courseCardPart1 commerceCardPart1' data-aos="flip-left">
                                    <h1 style={{ textAlign: 'center' }}>Part 1</h1>
                                    <hr />
                                    <ul className="courseUl" style={{ paddingLeft: "0"}}>
                                        <li className='star'>Kannada</li>
                                        <li className='star'>English</li>
                                        <li className='star'>Hindi</li>
                                        <li className='star'>Sanskrit</li>
                                    </ul>

                                </div>

                                <div className='courseBg commerceBg' data-aos="zoom-in" data-aos-duration='2000'></div>

                                <div className='courseCardPart2  commerceCardPart2' data-aos="flip-right">
                                    <h1 style={{ textAlign: 'center' }}>Part 2</h1>
                                    {/* <hr /> */}
                                    {/* <div className='row'>
                                        <div className='col-lg-9 col-8'>
                                            <ul>
                                                <li className='star'>Economics</li>
                                                <li className='star'>Business Studies</li>
                                                <li className='star'>Accountancy</li>
                                                <li className='star'>Computer science</li>
                                            </ul>
                                        </div>
                                        <div className='col-lg-3 col-4 d-flex justify-content-center align-items-center' style={{ borderLeft: '2px solid white' }}>
                                            EBACS
                                        </div>
                                    </div> */}
                                    <hr />
                                    <div className='row'>
                                        <div className='col-lg-9 col-8'>
                                            <ul>
                                                <li className='star'>Economics</li>
                                                <li className='star'>Business Studies</li>
                                                <li className='star'>Accountancy</li>
                                                <li className='star'>Statistics</li>
                                            </ul>
                                        </div>
                                        <div className='col-lg-3 col-4 d-flex justify-content-center align-items-center ' style={{ borderLeft: '2px solid white' }}>
                                            EBAS
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='commerceRightPart col-lg-5 col-12  d-flex align-items-center'>
                                <h1 className="courseRound courseRoundCommerce">Commerce</h1>
                                <div className="courseImage commerceImage" data-aos="fade-left"></div>
                                <span class="imageDots imageDotsCommerce" data-aos="fade-left"></span>

                            </div>
                        </div>


                        
                    </section>

                </Container>
            </Layout>
        </div>
    )
}

export default Courses
